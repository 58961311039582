import { Atom } from ":mods";
import { HomeHeroSectionProps } from "../../model";

export default function HeroSection({ title, description, buttons }: HomeHeroSectionProps) {
  const button_values = Object.values(buttons);
  return (
    <section id="home-hero-section" class="flex flex-col bg#p py-220px  justify-center items-center text-white">
      <p class="w-1007px mx-auto px-40px text-center text-76px font-bold leading-84px ">{title}</p>
      <p class="my-40px text-21px font-bold w-585px mx-auto ">{description}</p>
      <div class="flex flex-row-reverse gap-20px text-17px font-bold">
        {button_values.map((button) => {
          return (
            <Atom.Buttons.Button
              theme={button.theme as any}
              outlined={button.outlined as any}
              link={button.link}
              text={button.text}
            />
          );
        })}
      </div>
    </section>
  );
}
